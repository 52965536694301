import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-jacarta-900">
      <div className="container mx-auto px-6 py-12">
        {/* Top Section */}
        <div className="flex flex-col md:flex-row md:justify-between">
          {/* Logo and Description */}
          <div className="mb-8 md:mb-0 md:w-1/3">
            <Link href="#">
              <a>
                <img
                  src="/images/logo.png"
                  className="h-8 dark:hidden"
                  alt="mtion.xyz | live streaming platform"
                />
                <img
                  src="/images/logo_white.png"
                  className="h-8 hidden dark:block"
                  alt="mtion.xyz | live streaming platform"
                />
              </a>
            </Link>
            <p className="mt-4 text-gray-600 dark:text-jacarta-300">
              The ultimate experience engine for communities
            </p>
            {/* Social Icons */}
            <div className="flex mt-6 space-x-4">
              {socialIcons.map((item) => (
                <Link href={item.href} key={item.id}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-accent dark:text-jacarta-300 dark:hover:text-white"
                  >
                    <svg
                      className="h-5 w-5 fill-current"
                      aria-hidden="true"
                    >
                      <use xlinkHref={`/icons.svg#icon-${item.text}`}></use>
                    </svg>
                  </a>
                </Link>
              ))}
            </div>
          </div>
          {/* Footer Menu */}
          <div className="grid grid-cols-2 gap-8 md:w-2/3 md:grid-cols-4">
            {footerMenuList.map((single) => (
              <div key={single.id}>
                <h3 className="text-sm font-semibold text-gray-700 dark:text-white">
                  {single.title}
                </h3>
                <ul className="mt-4 space-y-2">
                  {single.list.map((item) => (
                    <li key={item.id}>
                      <Link href={item.href}>
                        <a className="text-gray-600 hover:text-accent dark:text-jacarta-300 dark:hover:text-white">
                          {item.text}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom Section */}
        <div className="mt-12 border-t border-gray-200 dark:border-jacarta-700 pt-8">
          <div className="flex flex-col md:flex-row md:justify-between">
            <p className="text-sm text-gray-600 dark:text-jacarta-400">
              © {new Date().getFullYear()} mtion interactive inc. — Made with ❤️ in 🍁
            </p>
            <ul className="mt-4 flex space-x-4 text-sm text-gray-600 dark:text-jacarta-400 md:mt-0">
              <li>
                <Link href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms and Conditions
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/privacy">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy Policy
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
