// Meta.jsx
import Head from "next/head";
import Script from 'next/script';
const Meta = ({ title, keyword, desc, image, url,  scripts = [] }) => {
  return (
    <div>
      <Head>
        <title>
          {title} || Create, Play, Remix. Redefine Play with mtion
        </title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={image} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="mtion.xyz" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@mtionapp" />
        <meta name="twitter:creator" content="@mtionapp" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="robots" content="index, follow" />

        {scripts.map((script, index) => (
          <Script
          key={index}
          {...(script.id ? { id: script.id } : {})}
          {...(script.src ? { src: script.src } : {})}
          {...(script.strategy ? { strategy: script.strategy } : { })}
          {...(script.defer ? { defer: true } : {})}
          >
            {script.content && `${script.content}`}
          </Script>
        ))}
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title:
    "mtion.xyz | Create, Play, Remix. Redefine Play with mtion",
  keyword:
    "create, play, remix, UGC platform, interactive experiences, social gaming, game creation, immersive worlds, storytelling, mtion, player-generated content, creative expression",
  desc: "Redefine play, shape new worlds, and unleash your story. mtion empowers anyone to build interactive experiences and share their creativity.",
  image: "/images/experiences/1-Avatar.png",
  url: "https://www.mtion.xyz",
};

export default Meta;
