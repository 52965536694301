const footerMenuList = [

    {
        id: 2,
        title: "Company",
        diffClass: "",
        list: [{
            id: 1,
            href: "https://store.steampowered.com/app/1920720?utm_source=website&utm_campaign=front_page",
            text: "Studio App",
        },
        {
            id: 2,
            href: "https://docs.mtion.xyz/mtion",
            text: "Documentation",
        },
        {
            id: 3,
            href: "https://github.com/mtion-tv/MtionSDK-StarterProject",
            text: "SDK",
        },


        {
            id: 6,
            href: "/legal",
            text: "Legal",
        },
        {
            id: 5,
            href: "/privacy",
            text: "Privacy Policy",
        },
        {
            id: 3,
            href: "/careers",
            text: "Careers",
        },
        {
            id: 4,
            href: "/about",
            text: "About",
        },
        ],
    },

];

const socialIcons = [{
    id: 1,
    href: "https://twitter.com/mtionapp",
    text: "twitter",
},
{
    id: 2,
    href: "https://discord.com/invite/CuRyapecDq",
    text: "discord",
},
{
    id: 5,
    href: "https://www.youtube.com/@mtionapp",
    text: "youtube",
},
];

export { footerMenuList, socialIcons };